import { BASE_URL } from "./base";

export function getLivePagesCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/admin/count`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get live pages");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getPage(slug: string): Promise<Page> {
    return new Promise((resolve, reject) => {
        slug = slug.toLowerCase();
        if (slug.charAt(0) === "/") slug = slug.substring(1);
        slug = encodeURIComponent(slug);
        fetch(`${BASE_URL}/page/${slug}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Page>;
                    if (!data.success) return reject("Failed to get page");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

// Get page by id, used for admin editing
export function getPageByID(id: string): Promise<Page> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/page/id/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Page>;
                    if (!data.success) return reject("Failed to get page");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getLivePages(): Promise<BasicPageData[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/live`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<BasicPageData[]>;
                    if (!data.success) return reject("Failed to get live pages");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getAllPages(): Promise<BasicPageData[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/admin/all`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<BasicPageData[]>;
                    if (!data.success) return reject("Failed to get live pages");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function updatePage(page: Page): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/admin/${page.id}`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(page),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<boolean>;
                    if (!data.success) return reject("Failed to update page");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function createPage(page: Page): Promise<Page> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/admin`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(page),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Page>;
                    if (!data.success) return reject("Failed to create page");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function deletePage(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/pages/admin/${id}`, {
            method: "DELETE",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<boolean>;
                    if (!data.success) return reject("Failed to delete page");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
