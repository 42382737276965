import { Typography } from "@mui/material";

interface Props {
    html: string;
}

const RichRender = ({ html }: Props) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        ></div>
    );
};

export default RichRender;
