import EventIcon from "@mui/icons-material/Event";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LaunchIcon from "@mui/icons-material/Launch";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { Button, Card, CardActions, CardContent, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";

interface Props {
    event: PublicEventItem;
}

const dateFormat: string = "MMM DD, YYYY h:mma";

const EventCard = ({ event }: Props) => {
    return (
        <Card>
            <CardContent>
                <Typography variant="h4">{event.title}</Typography>
                <Stack spacing={1}>
                    <Stack direction={"row"} spacing={1}>
                        <EventIcon />
                        <Typography variant="body2">
                            {dayjs(event.start).format(dateFormat)}-{dayjs(event.end).format(dateFormat)}
                        </Typography>
                    </Stack>
                    {!event.external && (
                        <Tooltip title="Location" placement="left">
                            <Stack direction={"row"} spacing={1}>
                                <PinDropIcon />
                                <Typography variant="body2">{event.location}</Typography>
                            </Stack>
                        </Tooltip>
                    )}
                    {event.external && (
                        <Tooltip title="Hosted by" placement="left">
                            <Stack direction={"row"} spacing={1}>
                                <GroupWorkIcon />
                                <Typography variant="body2">{event.host}</Typography>
                            </Stack>
                        </Tooltip>
                    )}
                </Stack>
            </CardContent>
            <CardActions>
                {event.external ? (
                    <Button size="small" startIcon={<LaunchIcon />} href={`/event/${event.url}`}>
                        Link
                    </Button>
                ) : (
                    <Button size="small" href={`/event/${event.url}`}>
                        Register
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default EventCard;
