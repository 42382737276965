import { Container } from "@mui/material";
import { useContext, useEffect } from "react";
import { fetchKV } from "../api/kv";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";

export function OrdealSurveyPage() {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Ordeal Survey", navHighlight: "/ordeal", hideFooter: true }));
        }

        fetchKV("ordeal_survey_url").then((url) => {
            window.location.href = url;
        });
    }, [setSiteState]);

    return <Container sx={{ mt: "60px", textAlign: "center" }}>Redirecting...</Container>;
}
