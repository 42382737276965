import { BASE_URL } from "./base";

export function fetchKV(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/kv/${key}`)
            .then((res) => res.json())
            .then((json) => {
                if (json.success) {
                    resolve(json.data);
                } else {
                    reject(json.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function setKV(key: string, value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/kv/${key}`, {
            method: "POST",
            body: JSON.stringify({ value: value }),
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.success) {
                    resolve(true);
                } else {
                    reject(json.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
