import { Container, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const AdminSiteAnnouncements = () => {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Site Announcements - Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Site Announcements
                </Typography>
            </Container>
        </AdminLayout>
    );
};

export default AdminSiteAnnouncements;
