import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import { Button, ButtonGroup, Container, IconButton, Paper, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { deleteRoundtable, getRoundtableList, newRoundtable, putRoundtable } from "../../api/roundtable";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";

const AdminRoundtable = () => {
    const { setSiteState } = useContext(SiteStateContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [dates, setDates] = useState<RoundTableDate[]>([]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Roundtable - Admin", navHighlight: "/admin/roundtable", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        getRoundtableList()
            .then((dates) => {
                setDates(dates);
                setLoading(false);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [setDates]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Roundtable
                </Typography>
                <div style={{ display: "flex", margin: "1rem" }}>
                    <ButtonGroup disableElevation variant="outlined" sx={{ marginLeft: "auto" }}>
                        <Button
                            startIcon={<CreateIcon />}
                            onClick={() => {
                                newRoundtable().then((info) => {
                                    setDates([...dates, info]);
                                });
                            }}
                        >
                            Add Date
                        </Button>
                    </ButtonGroup>
                </div>
                {!loading && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {dates.map((date, i) => (
                            <Paper
                                key={i}
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    m: 1,
                                }}
                            >
                                <DateTimePicker
                                    value={dayjs(date.dateTime)}
                                    onChange={(val) => {
                                        if (!val) return;
                                        date.dateTime = val.toDate();
                                        setDates([...dates]);
                                    }}
                                    sx={{ width: "100%" }}
                                />
                                <div
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                >
                                    <IconButton
                                        onClick={async () => {
                                            await deleteRoundtable(date.id);
                                            const d = dates.filter((d) => d.id !== date.id);
                                            setDates(d);
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={async () => {
                                            const current = dates.find((d) => d.id === date.id);
                                            if (!current) return;
                                            const res = await putRoundtable(current.id, current.dateTime);
                                            console.log(res);
                                        }}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </div>
                            </Paper>
                        ))}
                    </LocalizationProvider>
                )}
            </Container>
        </AdminLayout>
    );
};

export default AdminRoundtable;
