import { Container, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPages, getPageByID } from "../../api/page";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";
import RichEditor from "../../components/RichEditor";

const AdminPageEdit = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const [page, setPage] = useState<Page>();

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (!params.id) return navigate("/admin/pages");

        getPageByID(params.id)
            .then((page) => {
                setPage(page);

                if (setSiteState) {
                    setSiteState(fixSiteState({ title: `${page.title} - Pages - Admin`, navHighlight: "/admin/pages", adminPage: true }));
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [setSiteState, navigate, params.id]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                {page && (
                    <RichEditor
                        content={page.content}
                        setContent={function (content: string): void {
                            page.content = content;
                        }}
                        editable={true}
                        id={page.id}
                    />
                )}
            </Container>
        </AdminLayout>
    );
};

export default AdminPageEdit;
