import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#E6E7E8",
        },
        primary: {
            main: "#0391f1",
        },
        secondary: {
            main: "#36454F",
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: "MuseoSans300",
        h1: {
            fontFamily: "MuseoSlab700",
        },
        h2: {
            fontFamily: "MuseoSlab300",
        },
        h3: {
            fontFamily: "MuseoSans700",
        },
        body1: {
            fontFamily: "MuseoSans300",
        },
        body2: {
            fontFamily: "MuseoSans300",
        },
    },
});

export default theme;
