import { Container } from "@mui/material";
import { useContext, useEffect } from "react";
import CONFIG from "../config";
import { SiteStateContext } from "../contexts/SiteStateContext";
import gaEvents from "../util/gaEvent";
import { fixSiteState } from "../util/site_state";

export function NewsletterPage() {
    const { setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Newsletter", navHighlight: "/newsletter", hideFooter: true }));
        }
    }, [setSiteState]);

    gaEvents.newsletter.newsletter_redirect();

    window.location.href = CONFIG.newsletter.url;

    return <Container sx={{ mt: "60px", textAlign: "center" }}>Redirecting...</Container>;
}
