import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import { Chart, Legend, PieSeries, Title, Tooltip } from "@devexpress/dx-react-chart-material-ui";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import MailIcon from "@mui/icons-material/Mail";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventById, getRegistrationsById } from "../../../api/event";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const AdminEventView = () => {
    const params = useParams();
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [event, setEvent] = useState<EventItem>();
    const [registrations, setRegistrations] = useState<EventRegistrationEntry[]>([]);
    const navigate = useNavigate();
    const [ready, setReady] = useState(false);

    // Charts
    const [units, setUnits] = useState<{ label: string; val: number }[]>([]);
    const [unit_types, setUnitTypes] = useState<{ label: string; val: number }[]>([]);
    const [groups, setGroups] = useState<{ label: string; val: number }[]>([]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "View - Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: EventIcon,
                            text: "All Events",
                            onClick: () => {
                                navigate(`/admin/events`);
                            },
                        },
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            onClick: () => {
                                navigate(`/event/${event?.url}`);
                            },
                        },
                        {
                            icon: EditIcon,
                            text: "Edit",
                            onClick: () => {
                                navigate(`/admin/events/${event?.id}/edit`);
                            },
                        },
                        {
                            icon: MailIcon,
                            text: "Send Email",
                            onClick: () => {
                                navigate(`/admin/events/${event?.id}/mail`);
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, event]);

    // Data fetching
    useEffect(() => {
        if (!params.id) return;
        getEventById(params.id)
            .then((ev) => {
                getRegistrationsById(ev.id).then((regs) => {
                    setRegistrations(regs);
                });
                setEvent(ev);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [params]);

    // Charting
    useEffect(() => {
        if (!event) return;
        console.log(`Rebuilding charts w/ ${registrations.length} registrations`);

        // Unit Charts
        if (event.input_unit) {
            const unit_map = registrations.map((reg) => reg.unit);
            const unit_labels = Array.from(new Set(unit_map));

            // Unit distribution
            setUnits(
                unit_labels
                    .map((unit) => {
                        return { label: unit, val: registrations.filter((reg) => reg.unit === unit).length };
                    })
                    .sort((a, b) => a.val - b.val)
            );

            console.log(units);

            // Unit type distribution
            setUnitTypes([
                { label: "Troop", val: unit_map.filter((label) => label.includes("troop")).length },
                { label: "Crew", val: unit_map.filter((label) => label.includes("crew")).length },
                { label: "Ship", val: unit_map.filter((label) => label.includes("ship")).length },
                { label: "Pack", val: unit_map.filter((label) => label.includes("pack")).length },
            ]);
        }

        // Group Charts
        if (event.input_group) {
            // Age group distribution
            setGroups([
                { label: "Youth", val: registrations.filter((reg) => reg.group === "youth").length },
                { label: "Youth+", val: registrations.filter((reg) => reg.group === "ya").length },
                { label: "Adult", val: registrations.filter((reg) => reg.group === "adult").length },
            ]);
        }

        setTimeout(() => {
            setReady(true);
        }, 1000);
    }, [event, registrations]);

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 200, type: "string" },
        {
            field: "dateTime",
            headerName: "Timestamp",
            width: 200,
            type: "dateTime",
            valueGetter: (params) => {
                return new Date(params.value);
            },
        },
        { field: "email", headerName: "Email", width: 200 },
    ];

    if (event?.input_name) {
        columns.push({ field: "name", headerName: "Name", width: 200 });
    }

    if (event?.input_unit) {
        columns.push({ field: "unit", headerName: "Unit", width: 200 });
    }

    if (event?.input_group) {
        columns.push({ field: "group", headerName: "Group", width: 200 });
    }

    if (event?.input_phone) {
        columns.push({ field: "phone", headerName: "Phone", width: 200 });
    }

    if (event?.objects) {
        event.objects.forEach((obj) => {
            columns.push({ field: `object_${obj.id}`, headerName: obj.title, width: 200 });
        });
    }

    if (event?.input_notes) {
        columns.push({ field: "notes", headerName: "Notes", width: 300, type: "string" });
    }

    function embed_objects(regs: EventRegistrationEntry[]) {
        regs.forEach((reg) => {
            const obj = JSON.parse(reg.objects);
            event?.objects.forEach((o) => {
                // @ts-ignore
                reg[`object_${o.id}`] = obj[o.id];
            });
        });

        return regs;
    }

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                        View Event
                    </Typography>
                    {event && ready ? (
                        <>
                            <Typography variant="h3">{event.title}</Typography>
                            <Paper sx={{ p: 3, mt: 2 }}>
                                <DataGrid
                                    rows={embed_objects(registrations)}
                                    columns={columns}
                                    slots={{
                                        toolbar: GridToolbar,
                                    }}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                // Hide columns status and traderName, the other columns will remain visible
                                                id: false,
                                            },
                                        },
                                    }}
                                    density="compact"
                                />
                            </Paper>
                            {registrations.length > 0 && (
                                <Grid container spacing={2} sx={{ mt: 3 }}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 3 }}>
                                            <Typography variant="body1">
                                                {registrations.length} registrations for {event.title}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    {event.input_unit && (
                                        <Grid item xs={12} md={4}>
                                            <Paper>
                                                <Chart data={units}>
                                                    <Title text="Units" />
                                                    <PieSeries valueField="val" argumentField="label" />
                                                    <EventTracker />
                                                    <Tooltip
                                                        contentComponent={(props) => {
                                                            return (
                                                                <div>
                                                                    {units[props.targetItem.point].label} - {props.text}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                    <Animation />
                                                </Chart>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {event.input_unit && (
                                        <Grid item xs={12} md={4}>
                                            <Paper>
                                                <Chart data={unit_types}>
                                                    <Title text="Unit Types" />
                                                    <PieSeries valueField="val" argumentField="label" />
                                                    <Legend position="bottom" />
                                                    <EventTracker />
                                                    <Tooltip
                                                        contentComponent={(props) => {
                                                            return (
                                                                <div>
                                                                    {unit_types[props.targetItem.point].label} - {props.text}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                    <Animation />
                                                </Chart>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {event.input_group && (
                                        <Grid item xs={12} md={4}>
                                            <Paper>
                                                <Chart data={groups}>
                                                    <Title text="Age Groupings" />
                                                    <PieSeries valueField="val" argumentField="label" />
                                                    <Legend position="bottom" />
                                                    <EventTracker />
                                                    <Tooltip
                                                        contentComponent={(props) => {
                                                            return (
                                                                <div>
                                                                    {groups[props.targetItem.point].label} - {props.text}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                    <Animation />
                                                </Chart>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </LocalizationProvider>
            </Container>
        </AdminLayout>
    );
};

export default AdminEventView;
