import { Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import AdminLayout from "../admin/AdminLayout";
import { fixSiteState } from "../../util/site_state";
import { PasswordReset } from "../../components/PasswordReset";

const AdminPasswordReset = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const [errMessage, setErrMessage] = useState<string | null>(null);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Password Reset - Admin", navHighlight: "/admin/reset-password", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Password Reset
                </Typography>
                <PasswordReset redirect="/admin" />
            </Container>
        </AdminLayout>
    );
};

export default AdminPasswordReset;
