import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { createUser, deleteUser, getAllUsers, resetUser, updateUser } from "../../../api/user";
import { AuthContext } from "../../../contexts/AuthContext";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const AdminUser = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const [users, setUsers] = useState<User[]>([]);
    const { authUser } = useContext(AuthContext);

    const [showNewUser, setShowNewUser] = useState(false);
    const [newUserName, setNewUserName] = useState<string>("");
    const [newUserEmail, setNewUserEmail] = useState<string>("");

    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [currentTargetUser, setCurrentTargetUser] = useState<User | null>(null);

    const [isCreating, setIsCreating] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Users - Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    const refreshUsers = () => {
        getAllUsers().then((u) => {
            setUsers(u);
        });
    };

    useEffect(() => {
        refreshUsers();
    }, []);

    return (
        <AdminLayout>
            {/* Create new user modal */}
            <Dialog
                open={showNewUser}
                onClose={() => {
                    if (isCreating) return;
                    setShowNewUser(false);
                    setNewUserName("");
                    setNewUserEmail("");
                }}
            >
                <DialogTitle>Create new user</DialogTitle>
                <DialogContent>
                    <DialogContentText>To create a new user, enter their name and email address. They will receive an email with instructions to set their password.</DialogContentText>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={newUserName}
                                onChange={(e) => {
                                    setNewUserName(e.target.value);
                                }}
                                disabled={isCreating}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={newUserEmail}
                                onChange={(e) => {
                                    setNewUserEmail(e.target.value);
                                }}
                                disabled={isCreating}
                                type="email"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setShowNewUser(false);
                            setNewUserName("");
                            setNewUserEmail("");
                        }}
                        disabled={isCreating}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            setIsCreating(true);

                            createUser({
                                name: newUserName,
                                email: newUserEmail,
                            }).then((u) => {
                                refreshUsers();
                                setIsCreating(false);
                                setShowNewUser(false);
                                setNewUserName("");
                                setNewUserEmail("");
                            });
                        }}
                        loading={isCreating}
                    >
                        Create
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Confirm password reset modal */}
            <Dialog open={showResetPassword} onClose={() => setShowResetPassword(false)}>
                <DialogTitle>Reset password</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to reset this user's password? They will receive an email with instructions to set their password.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResetPassword(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            setShowResetPassword(false);
                            if (!currentTargetUser) return;
                            resetUser(currentTargetUser.id).then(() => {
                                refreshUsers();
                            });
                        }}
                    >
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm user delete modal */}
            <Dialog open={showDeleteUser} onClose={() => setShowDeleteUser(false)}>
                <DialogTitle>Delete user</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this user? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteUser(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            setShowDeleteUser(false);
                            if (!currentTargetUser) return;
                            deleteUser(currentTargetUser.id).then(() => {
                                refreshUsers();
                            });
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Page */}
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Users
                </Typography>
                <Button variant="outlined" sx={{ mb: 2 }} onClick={() => setShowNewUser(true)}>
                    Create
                </Button>
                {users.map((u: User) => (
                    <Accordion key={u.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>{u.name}</Typography>
                            <Typography sx={{ color: "text.secondary" }}>{u.id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        value={u.name}
                                        onChange={(e) => {
                                            u.name = e.target.value;
                                            setUsers([...users]);
                                        }}
                                        disabled={u.role === "root" && authUser?.role !== "root"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        value={u.email}
                                        onChange={(e) => {
                                            u.email = e.target.value;
                                            setUsers([...users]);
                                        }}
                                        disabled={u.role === "root" && authUser?.role !== "root"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        value={u.title}
                                        onChange={(e) => {
                                            u.title = e.target.value;
                                            setUsers([...users]);
                                        }}
                                        disabled={u.role === "root" && authUser?.role !== "root"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        label="Role"
                                        variant="standard"
                                        fullWidth
                                        value={u.role}
                                        onChange={(ev) => {
                                            u.role = ev.target.value as UserRole;
                                            setUsers([...users]);
                                        }}
                                        disabled={u.role === "root" && authUser?.role !== "root"}
                                    >
                                        {[
                                            { a: "user", b: "User", c: false },
                                            { a: "admin", b: "Admin", c: false },
                                            { a: "root", b: "Root", c: authUser?.role !== "root" },
                                        ].map((option) => (
                                            <MenuItem key={option.a} value={option.a} disabled={option.c}>
                                                {option.b}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Stack spacing={2} sx={{ mt: 2 }}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="warning"
                                    disabled={u.role === "root" && authUser?.role !== "root"}
                                    onClick={() => {
                                        setCurrentTargetUser(u);
                                        setShowResetPassword(true);
                                    }}
                                >
                                    Force Password Reset
                                </Button>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="error"
                                    disabled={u.role === "root" && authUser?.role !== "root"}
                                    onClick={() => {
                                        setCurrentTargetUser(u);
                                        setShowDeleteUser(true);
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="success"
                                    onClick={() => {
                                        updateUser(u.id, u).then((u) => {
                                            refreshUsers();
                                        });
                                    }}
                                    disabled={u.name === "" || u.email === "" || u.title === "" || (u.role === "root" && authUser?.role !== "root")}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </AdminLayout>
    );
};

export default AdminUser;
