import { LoadingButton } from "@mui/lab";
import { Button, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin, fetchAuthUser } from "../../api/auth";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { AuthContext } from "../../contexts/AuthContext";
import AdminLayout from "../admin/AdminLayout";
import { fixSiteState } from "../../util/site_state";
import { updateUser, updateUserPassword } from "../../api/user";
import { PasswordReset } from "../../components/PasswordReset";

const AdminAccount = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    //
    const [name, setName] = useState<string>("");
    const [title, setTitle] = useState<string>("");

    //
    const [loading, setLoading] = useState<boolean>(false);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Account - Admin", navHighlight: "/admin/account", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        fetchAuthUser().then((user) => {
            if (setAuthUser) setAuthUser(user);
            setName(user.name);
            setTitle(user.title ?? "");
        });
    }, []);

    function saveBasicInfo() {
        if (!authUser) return;
        if (name.length <= 2) return console.warn("Name too short");
        updateUser(authUser.id, { name, title })
            .then((user) => {
                if (user && setAuthUser) {
                    setAuthUser(user);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Account
                </Typography>
                <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
                    Profile
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={(ev) => {
                                setName(ev.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Title"
                            value={title}
                            onChange={(ev) => {
                                setTitle(ev.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <Button onClick={saveBasicInfo}>Save</Button>
                    </Grid>
                </Grid>
                <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
                    Password
                </Typography>
                <PasswordReset />
            </Container>
        </AdminLayout>
    );
};

export default AdminAccount;
