import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import useSyntaxHighlighterTheme from "./useSyntaxHighlighterTheme";
import { Box, Divider, Link, Typography } from "@mui/material";

interface Props {
    markdown: string;
}

const MarkdownRender = ({ markdown }: Props) => {
    const mdTheme = useSyntaxHighlighterTheme();

    return (
        // <ReactMarkdown
        //     children={markdown}
        //     remarkPlugins={[remarkGfm]}
        //     skipHtml={false}
        //     components={{
        //         code({ node, inline, className, children, ...props }) {
        //             const match = /language-(\w+)/.exec(className || "");
        //             return !inline && match ? (
        //                 <SyntaxHighlighter children={String(children).replace(/\n$/, "")} style={mdTheme as any} language={match[1]} PreTag="div" {...props} />
        //             ) : (
        //                 <code {...props}>
        //                     {children}
        //                 </code>
        //             );
        //         },
        //         h1({ node, className, children, ...props }) {
        //             return <Typography variant="h1" children={children} {...props} />;
        //         },
        //         h2({ node, className, children, ...props }) {
        //             return <Typography variant="h2" children={children} {...props} />;
        //         },
        //         h3({ node, className, children, ...props }) {
        //             return <Typography variant="h3" children={children} {...props} />;
        //         },
        //         h4({ node, className, children, ...props }) {
        //             return <Typography variant="h4" children={children} {...props} />;
        //         },
        //         h5({ node, className, children, ...props }) {
        //             return <Typography variant="h5" children={children} {...props} />;
        //         },
        //         h6({ node, className, children, ...props }) {
        //             return <Typography variant="h6" children={children} {...props} />;
        //         },
        //         p({ node, className, children, ...props }) {
        //             return <Typography paragraph children={children} {...props} />;
        //         },
        //         a({ node, className, children, ...props }) {
        //             return <Link children={children} {...props} />;
        //         },
        //         li({ node, className, children, ...props }) {
        //             return <Box component="li" mt={1} {...props} />;
        //         },
        //         hr({ node, className, children, ...props }) {
        //             return <Divider />;
        //         },
        //         img({ node, className, children, ...props }) {
        //             return <img alt="" {...props} />;
        //         },
        //         blockquote({ node, className, children, ...props }) {
        //             return <blockquote children={children} />;
        //         },
        //     }}
        // />
        <>Failed to render markdown</>
    );
};

export default MarkdownRender;
