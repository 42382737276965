import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import SiteStateContextProvider from "./contexts/SiteStateContext";
import Layout from "./Layout";
import AdminActivity from "./pages/admin/Activity";
import AdminAdvanced from "./pages/admin/advanced/Advanced";
import AdminAuditLog from "./pages/admin/advanced/AuditLog";
import AdminDev from "./pages/admin/advanced/Dev";
import AdminSiteAnnouncements from "./pages/admin/advanced/SiteAnnouncements";
import AdminSiteManager from "./pages/admin/advanced/SiteManager";
import AdminUser from "./pages/admin/advanced/Users";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminDocs from "./pages/admin/Docs";
import AdminEventConfig from "./pages/admin/event/EventConfig";
import AdminEventCreate from "./pages/admin/event/EventCreate";
import AdminEventEdit from "./pages/admin/event/EventEdit";
import AdminEventMail from "./pages/admin/event/EventMail";
import AdminEvents from "./pages/admin/event/Events";
import AdminEventView from "./pages/admin/event/EventView";
import AdminMessages from "./pages/admin/Messages";
import AdminViewMessage from "./pages/admin/MessageView";
import AdminOfficers from "./pages/admin/Officers";
import AdminPageEdit from "./pages/admin/PageEdit";
import AdminPages from "./pages/admin/Pages";
import AdminRoundtable from "./pages/admin/Roundtable";
import AdminShop from "./pages/admin/Shop";
import CalendarPage from "./pages/CalendarPage";
import EventListPage from "./pages/EventListPage";
import EventPage from "./pages/EventPage";
import FeedbackPage from "./pages/FeedbackPage";
import HomePage from "./pages/HomePage";
import MaintenancePage from "./pages/Maintenance";
import MarkdownOrNotFound from "./pages/MarkdownOrNotFound";
import { NewsletterPage } from "./pages/NewsletterPage";
import { NewsPage } from "./pages/NewsPage";
import OfficersPage from "./pages/OfficersPage";
import { OrdealSurveyPage } from "./pages/OrdealSurveyPage";
import { ServicePage } from "./pages/ServicePage";
import ShopCartPage from "./pages/ShopCartPage";
import ShopItemPage from "./pages/ShopItemPage";
import ShopPage from "./pages/ShopPage";
import AdminAccount from "./pages/user/Account";
import AdminLoginPage from "./pages/user/Login";
import AdminPasswordReset from "./pages/user/PasswordReset";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                { path: "/", element: <HomePage /> },
                { path: "calendar", element: <CalendarPage /> },
                { path: "officers", element: <OfficersPage /> },
                { path: "events", element: <EventListPage /> },
                { path: "events/:slug", element: <EventPage /> },
                { path: "event/:slug", element: <EventPage /> },
                { path: "e/:slug", element: <EventPage /> },
                { path: "newsletter", element: <NewsletterPage /> },
                { path: "news", element: <NewsPage /> },
                { path: "feedback", element: <FeedbackPage /> },
                { path: "maintenance", element: <MaintenancePage /> },
                { path: "shop", element: <ShopPage /> },
                { path: "shop/:id", element: <ShopItemPage /> },
                { path: "shop/cart", element: <ShopCartPage /> },
                { path: "service", element: <ServicePage /> },
                { path: "ordeal", element: <OrdealSurveyPage /> },
                {
                    path: "admin",
                    children: [
                        { path: "", element: <AdminDashboard /> },
                        { path: "login", element: <AdminLoginPage /> },
                        { path: "account", element: <AdminAccount /> },
                        { path: "reset-password", element: <AdminPasswordReset /> },
                        { path: "activity", element: <AdminActivity /> },
                        { path: "advanced", element: <AdminAdvanced /> },
                        { path: "advanced/audit-log", element: <AdminAuditLog /> },
                        { path: "advanced/dev", element: <AdminDev /> },
                        { path: "advanced/site-announcements", element: <AdminSiteAnnouncements /> },
                        { path: "advanced/site-manager", element: <AdminSiteManager /> },
                        { path: "advanced/users", element: <AdminUser /> },
                        { path: "officers", element: <AdminOfficers /> },
                        { path: "docs", element: <AdminDocs /> },
                        { path: "roundtable", element: <AdminRoundtable /> },
                        { path: "events", element: <AdminEvents /> },
                        { path: "events/:id/edit", element: <AdminEventEdit /> },
                        { path: "events/:id/view", element: <AdminEventView /> },
                        { path: "events/:id/config", element: <AdminEventConfig /> },
                        { path: "events/:id/mail", element: <AdminEventMail /> },
                        { path: "events/create", element: <AdminEventCreate /> },
                        { path: "messages", element: <AdminMessages /> },
                        { path: "messages/:id", element: <AdminViewMessage /> },
                        { path: "pages", element: <AdminPages /> },
                        { path: "pages/:id", element: <AdminPageEdit /> },
                        { path: "shop", element: <AdminShop /> },
                    ],
                },
                { path: "*", element: <MarkdownOrNotFound /> },
            ],
        },
    ]);

    return (
        <SiteStateContextProvider>
            <AuthContextProvider>
                <DndProvider backend={HTML5Backend}>
                    <RouterProvider router={router} />
                </DndProvider>
            </AuthContextProvider>
        </SiteStateContextProvider>
    );
}

export default App;
