import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";
import EventCard from "../components/EventCard";
import { getOpenEvents } from "../api/event";
import { useNavigate } from "react-router-dom";

const EventListPage = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const [events, setEvents] = useState<PublicEventItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Events",
                    navHighlight: "/events",
                    actions: [
                        {
                            icon: AddCircleIcon,
                            text: "Create Event",
                            onClick: () => {
                                navigate("/admin/events/create");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        getOpenEvents()
            .then((ev) => {
                setEvents(ev);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, []);

    return (
        <Container>
            <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                Events
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {events.length === 0 && <Typography variant="h5">No events are currently open.</Typography>}
                    <Grid container spacing={2}>
                        {events.map((event) => (
                            <Grid item xs={12} md={6} lg={4} key={event.id}>
                                <EventCard event={event} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </Container>
    );
};

export default EventListPage;
