import { Button, ButtonGroup, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import AdminLayout from "./AdminLayout";
import { fixSiteState } from "../../util/site_state";
import { deleteDocumentById, getPreSignedUrl, listDocuments } from "../../api/docs";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDownloadDocument } from "../../util/useDownloadDocument";
import { useMultiDownloadDocument } from "../../util/useMultiDownloadDocument";
import { LoadingButton } from "@mui/lab";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const AdminDocs = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);
    const { authUser, setAuthUser } = useContext(AuthContext);
    const [documents, setDocuments] = useState<DocumentItem[]>([]);
    const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const navigate = useNavigate();

    const { download } = useMultiDownloadDocument({
        preDownloading: () => {},
        postDownloading: () => {},
        onError: () => {
            console.log("error");
        },
    });

    function refresh() {
        listDocuments().then((docs) => {
            console.log("docs", docs);
            setDocuments(docs);
        });
    }

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Documents - Admin", navHighlight: "/admin/docs", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        refresh();
    }, []);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Dialog
                    open={uploadDialogOpen}
                    onClose={() => {
                        setUploadDialogOpen(false);
                    }}
                >
                    <DialogTitle>Upload new document</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Select the file to upload.</DialogContentText>
                        <input
                            type="file"
                            onChange={(event) => {
                                if (event.target.files) {
                                    const f = event.target.files[0];
                                    setFile(f);
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setUploadDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={uploadInProgress}
                            loadingPosition="start"
                            startIcon={<UploadFileIcon />}
                            onClick={async () => {
                                // Actually upload
                                if (file != null) {
                                    setUploadInProgress(true);
                                    getPreSignedUrl(file.name, file.type).then(async (url) => {
                                        console.log(url);
                                        fetch(url, {
                                            method: "PUT",
                                            body: file,
                                        })
                                            .then(async (res) => {
                                                console.log(res);
                                                if (!res.ok) {
                                                    setUploadInProgress(false);
                                                    return console.warn(res.statusText);
                                                }
                                                console.log("uploaded");
                                                setUploadInProgress(false);
                                                setUploadDialogOpen(false);
                                                refresh();
                                            })
                                            .catch((e) => {
                                                setUploadInProgress(false);
                                                console.warn(e);
                                            });
                                    });
                                }
                            }}
                        >
                            Upload
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Documents
                </Typography>
                <div style={{ display: "flex", margin: "1rem" }}>
                    <Typography variant="body2">Count: {documents.length ?? 0}</Typography>
                    <ButtonGroup disableElevation variant="outlined" sx={{ marginLeft: "auto" }}>
                        <Button
                            onClick={() => {
                                setUploadDialogOpen(true);
                            }}
                        >
                            Upload new
                        </Button>
                        <Button
                            onClick={() => {
                                refresh();
                            }}
                        >
                            Refresh
                        </Button>
                    </ButtonGroup>
                </div>
                {/* List documents */}
                {documents.map((doc) => (
                    <Card sx={{ mb: 2, p: 2 }} key={doc.ETag}>
                        <div style={{ display: "flex" }}>
                            <Typography variant="body1">{doc.Key}</Typography>
                            <Typography variant="body2" sx={{ marginLeft: 3 }}>
                                {doc.ETag.replaceAll('"', "")}
                            </Typography>
                            <ButtonGroup disableElevation variant="outlined" sx={{ marginLeft: "auto" }}>
                                <Button startIcon={<DownloadIcon />} href={`https://riverhawkoa-docs.s3.us-east-2.amazonaws.com/${doc.Key}`} target="__blank">
                                    Download
                                </Button>

                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                        // Delete document
                                        deleteDocumentById(doc.Key)
                                            .then(() => {
                                                console.log("Deleted");
                                                setDocuments((prev) => prev.filter((d) => d.Key !== doc.Key));
                                            })
                                            .catch((e) => {
                                                console.warn(e);
                                            });
                                    }}
                                >
                                    Delete
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Card>
                ))}
            </Container>
        </AdminLayout>
    );
};

export default AdminDocs;
