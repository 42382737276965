import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Container, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getArchivedEventsAdmin, getCurrentEventsAdmin, getExternalEventsAdmin } from "../../../api/event";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

const dateFormat: string = "MMM DD, YYYY h:mma";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function SelectorList(props: { events: EventItem[] }) {
    return (
        <List sx={{ bgcolor: "background.paper" }}>
            {props.events.map((item) => {
                return (
                    <ListItem disablePadding key={item.id}>
                        <ListItemButton href={`/admin/events/${item.id}/edit`}>
                            <ListItemText primary={`${item.title} (${item.url})`} secondary={`${dayjs(item.start).format(dateFormat)} to ${dayjs(item.end).format(dateFormat)}`} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}

const AdminEvents = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const [items, setItems] = useState<EventItem[][]>([[], [], []]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Events - Admin",
                    navHighlight: "/admin/events",
                    adminPage: true,
                    actions: [
                        {
                            icon: AddCircleIcon,
                            text: "New Event",
                            onClick: () => {
                                navigate("/admin/events/create");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        switch (tab) {
            case 0:
                getCurrentEventsAdmin()
                    .then((res) => {
                        let n = structuredClone(items);
                        n[0] = res;
                        setItems(n);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            case 1:
                getExternalEventsAdmin()
                    .then((res) => {
                        let n = structuredClone(items);
                        n[1] = res;
                        setItems(n);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            case 2:
                getArchivedEventsAdmin()
                    .then((res) => {
                        let n = structuredClone(items);
                        n[2] = res;
                        setItems(n);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
        }
    }, [tab]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Events
                </Typography>
                <Box sx={{ bgcolor: "background.paper", minHeight: 350 }}>
                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={tab}
                        onChange={(ev, val) => {
                            setTab(val);
                        }}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                        <Tab label="Current" />
                        <Tab label="External" />
                        <Tab label="Archived" />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Typography variant="h4">Current</Typography>
                        <SelectorList events={items[0]} />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Typography variant="h4">External</Typography>
                        <SelectorList events={items[1]} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Typography variant="h4">Archived</Typography>
                        <SelectorList events={items[2]} />
                    </TabPanel>
                </Box>
            </Container>
        </AdminLayout>
    );
};

export default AdminEvents;
