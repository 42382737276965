import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Alert, Button, ButtonGroup, Container, Grid, InputLabel, MenuItem, Select, Snackbar, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getShopConfig } from "../api/shop";
import { AuthContext } from "../contexts/AuthContext";
import { SiteStateContext } from "../contexts/SiteStateContext";
import gaEvents from "../util/gaEvent";
import { fixSiteState } from "../util/site_state";
import { Shop_ItemSize } from "./ShopPage";
import { LoadingButton } from "@mui/lab";

function ShopItemPage() {
    const { setSiteState } = useContext(SiteStateContext);
    const { authUser } = useContext(AuthContext);
    const [config, setConfig] = useState<Shop_Config>();
    const [adminOverride, setAdminOverride] = useState<boolean>(false);
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const [activeItem, setActiveItem] = useState<Shop_Item | null>(null);
    const [cart, setCart] = useState<Shop_CartItem[]>(sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")!) : []);

    const [curSize, setCurSize] = useState<Shop_ItemSize>(activeItem?.sizes[0] ?? Shop_ItemSize.ONE_SIZE);
    const [qty, setQty] = useState<number>(1);
    const [alert, setAlert] = useState<string | null>(null);

    const [addingToCart, setAddingToCart] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: `${activeItem?.name} - Shop`,
                    navHighlight: "/shop",
                    actions: [
                        {
                            icon: SellIcon,
                            text: "View Orders",
                            onClick: () => {
                                navigate("/admin/shop");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate, activeItem]);

    useEffect(() => {
        if (authUser && authUser.role !== "user") {
            setAdminOverride(true);
        }
    }, [authUser]);

    useEffect(() => {
        sessionStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    useEffect(() => {
        getShopConfig()
            .then((cfg) => {
                setConfig(cfg);
                setActiveItem(cfg.items.find((i) => i.id === params.id) ?? null);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [params.id]);

    useEffect(() => {
        setCurSize(activeItem?.sizes[0] ?? Shop_ItemSize.ONE_SIZE);
    }, [activeItem])

    if (!activeItem) {
        return null;
    }

    if (config?.analytics_enabled) {
        gaEvents.shop.view(
            //
            {
                //
                currency: "USD",
                value: activeItem.price,
                items: [
                    //
                    {
                        //
                        item_id: activeItem.id,
                        item_name: activeItem.name,
                        item_category: activeItem.cut,
                        quantity: 1,
                        price: activeItem.price,
                    },
                ],
            }
        );
    }

    if (!config) return null;

    return (
        <Container sx={{ mt: 6, mb: 3 }}>
            {!config.open && adminOverride && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    The shop is closed — because you are an admin, you still have access.
                </Alert>
            )}

            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item xs={12} md={6}>
                    {/* Title */}
                    <Typography variant="h2">Shop</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                    <ButtonGroup variant="outlined" sx={{ marginLeft: "auto" }}>
                        {/* Return to store */}
                        <Button
                            onClick={() => {
                                navigate("/shop");
                            }}
                        >
                            Return to Store
                        </Button>

                        {/* Cart */}
                        <Button
                            startIcon={<ShoppingCartIcon />}
                            onClick={() => {
                                navigate("/shop/cart");
                            }}
                        >
                            Cart ({cart.length})
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <img src={activeItem.image} alt={activeItem.name} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction={"column"} spacing={3}>
                        <Typography variant="h3">{activeItem.name}</Typography>
                        <Typography variant="h4">${activeItem.price}</Typography>
                        <Typography variant="h5">{activeItem.description}</Typography>
                        {activeItem.reference && (
                            <Typography variant="body1">
                                <a href={activeItem.reference}>Item Reference Link</a>
                            </Typography>
                        )}
                        <Grid container>
                            <Grid item md={9} xs={12}>
                                <div>
                                    <InputLabel id="size-select-label">Size</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="size-select-label"
                                        value={curSize}
                                        label="Size"
                                        onChange={(v) => {
                                            setCurSize(v.target.value as Shop_ItemSize);
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        {activeItem.sizes.map((sz) => (
                                            <MenuItem value={sz} key={sz}>
                                                {sz.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item md={1} xs={0}></Grid>
                            <Grid item md={2} xs={12}>
                                <div>
                                    <InputLabel id="qty-select-label">Quantity</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="qty-select-label"
                                        value={qty}
                                        label="Quantity"
                                        onChange={(v) => {
                                            setQty(v.target.value as number);
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((q) => (
                                            <MenuItem value={q} key={q}>
                                                {q.toFixed()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </Grid>
                        </Grid>
                    </Stack>

                    <LoadingButton
                        variant="contained"
                        loading={addingToCart}
                        onClick={() => {
                            setAddingToCart(true);

                            let addtl = [];
                            for (let i = 0; i < qty; i++) {
                                addtl.push({ id: activeItem.id, size: curSize });
                            }
                            setCart([...cart, ...addtl]);

                            if (config?.analytics_enabled) {
                                gaEvents.shop.add_to_cart(
                                    //
                                    {
                                        //
                                        currency: "USD",
                                        value: activeItem.price,
                                        items: [
                                            //
                                            {
                                                //
                                                item_id: activeItem.id,
                                                item_name: activeItem.name,
                                                item_category: activeItem.cut,
                                                quantity: 1,
                                                price: activeItem.price,
                                                item_variant: curSize,
                                            },
                                        ],
                                    }
                                );
                            }

                            setTimeout(() => {
                                navigate("/shop");
                            }, 1000);
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                    >
                        Add to cart
                    </LoadingButton>
                </Grid>
            </Grid>

            <Snackbar
                open={alert != null}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert(null);
                }}
                message={alert}
            />
        </Container>
    );
}

export default ShopItemPage;
