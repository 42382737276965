import CloseIcon from "@mui/icons-material/Close";
import SellIcon from "@mui/icons-material/Sell";
import { Alert, Button, ButtonGroup, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getShopConfig, postShopOrder } from "../api/shop";
import { AuthContext } from "../contexts/AuthContext";
import { SiteStateContext } from "../contexts/SiteStateContext";
import gaEvents from "../util/gaEvent";
import { fixSiteState } from "../util/site_state";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ShopCartPage = () => {
    const { setSiteState } = useContext(SiteStateContext);
    const { authUser } = useContext(AuthContext);
    const [config, setConfig] = useState<Shop_Config>();
    const navigate = useNavigate();

    const [adminOverride, setAdminOverride] = useState<boolean>(false);

    const [cart, setCart] = useState<Shop_CartItem[]>(sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")!) : []);

    const [alert, setAlert] = useState<string | null>(null);

    const [showFinal, setShowFinal] = useState<boolean>(false);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Shop",
                    navHighlight: "/shop",
                    actions: [
                        {
                            icon: SellIcon,
                            text: "View Orders",
                            onClick: () => {
                                navigate("/admin/shop");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, navigate]);

    useEffect(() => {
        if (authUser && authUser.role !== "user") {
            setAdminOverride(true);
        }
    }, [authUser]);

    useEffect(() => {
        getShopConfig()
            .then((cfg) => {
                setConfig(cfg);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        sessionStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    function placeOrder(name: string, email: string, unit: string) {
        if (!config) {
            return;
        }

        postShopOrder(name, email, unit, cart)
            .then((order) => {
                setAlert("Order placed!");
                setShowFinal(true);

                interface Item {
                    id: string;
                    name: string;
                    quantity: number;
                    size: string;
                }

                const converted: Item[] = [];

                cart.forEach((itm) => {
                    const existing = converted.find((v) => v.id === itm.id && v.size === itm.size);

                    if (existing) {
                        existing.quantity += 1;
                    } else {
                        converted.push({
                            id: itm.id,
                            name: config.items.find((i) => i.id === itm.id)?.name || "item not found",
                            quantity: 1,
                            size: itm.size,
                        });
                    }
                });

                if (config.analytics_enabled) {
                    gaEvents.shop.checkout({
                        currency: "USD",
                        transaction_id: order.id,
                        value: order.subtotal,
                        tax: order.total - order.subtotal,
                        shipping: 0,
                        items: converted.map((itm) => {
                            const actualItem = config?.items.find((i) => i.id === itm.id);

                            if (!actualItem) {
                                return null;
                            }

                            return {
                                item_id: itm.id,
                                item_name: itm.name,
                                item_category: actualItem.cut,
                                quantity: itm.quantity,
                                price: actualItem.price,
                                item_variant: itm.size,
                            };
                        }),
                    });
                }

                setCart([]);
            })
            .catch((err) => {
                console.error(err);
                setAlert("Error placing order.");
            });
    }

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [unit, setUnit] = useState<string>("");

    const [ready, setReady] = useState<boolean>(false);

    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

    useEffect(() => {
        setReady(name.length > 0 && email.length > 0 && unit.length > 0 && cart.length > 0);
    }, [name, email, unit, cart]);

    if (!config) return null;

    return (
        <>
            <Dialog
                open={confirmDialog}
                onClose={() => {
                    setConfirmDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">You will receive an email with your order details. Please bring cash to the next meeting to pay for your items.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirmDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setConfirmDialog(false);

                            placeOrder(name, email, unit);
                        }}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alert != null}
                autoHideDuration={6000}
                onClose={() => {
                    setAlert(null);
                }}
                message={alert}
            />

            <Container sx={{ mt: 6, mb: 3 }}>
                {!config.open && adminOverride && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        The shop is closed — because you are an admin, you still have access.
                    </Alert>
                )}

                <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                    <Grid item xs={12} md={6}>
                        {/* Title */}
                        <Typography variant="h2">Shop</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                        <ButtonGroup variant="outlined" sx={{ marginLeft: "auto" }}>
                            {/* Return to store */}
                            <Button
                                onClick={() => {
                                    navigate("/shop");
                                }}
                            >
                                Return to Store
                            </Button>

                            {/* Cart */}
                            <Button
                                startIcon={<ShoppingCartIcon />}
                                onClick={() => {
                                    navigate("/shop/cart");
                                }}
                            >
                                Cart ({cart.length})
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>

                {showFinal && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Order placed! You will receive an email confirmation shortly
                    </Alert>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            Cart
                        </Typography>
                        {cart.length === 0 && <Typography variant="h5">Your cart is empty.</Typography>}

                        {cart.map((itm, idx) => (
                            <Paper key={idx} sx={{ mt: 2 }} elevation={2}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <img src={config?.items.find((i) => i.id === itm.id)?.image} alt={config?.items.find((i) => i.id === itm.id)?.name} style={{ height: "60px" }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack>
                                            <Typography variant="h5">{config?.items.find((i) => i.id === itm.id)?.name}</Typography>
                                            <Typography variant="h6">{itm.size.toUpperCase()}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="h5">${(config?.items.find((i) => i.id === itm.id)?.price ?? 0).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => {
                                                setCart(cart.filter((_, i) => i !== idx));

                                                const actualItem = config?.items.find((i) => i.id === itm.id);

                                                if (!actualItem) {
                                                    return;
                                                }

                                                if (config?.analytics_enabled) {
                                                    gaEvents.shop.remove_from_cart(
                                                        //
                                                        {
                                                            //
                                                            currency: "USD",
                                                            value: actualItem.price,
                                                            items: [
                                                                //
                                                                {
                                                                    //
                                                                    item_id: actualItem.id,
                                                                    item_name: actualItem.name,
                                                                    item_category: actualItem.cut,
                                                                    quantity: 1,
                                                                    price: actualItem.price,
                                                                    item_variant: itm.size,
                                                                },
                                                            ],
                                                        }
                                                    );
                                                }
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h3">Checkout</Typography>
                        {/* Subtotal */}
                        <Typography variant="h5">Subtotal: ${cart.reduce((a, b) => a + (config?.items.find((i) => i.id === b.id)?.price ?? 0), 0).toFixed(2)}</Typography>
                        {/* Tax */}
                        <Typography variant="h5">Tax: ${(cart.reduce((a, b) => a + (config?.items.find((i) => i.id === b.id)?.price ?? 0), 0) * (config?.tax_multiplier ?? 1) - cart.reduce((a, b) => a + (config?.items.find((i) => i.id === b.id)?.price ?? 0), 0)).toFixed(2)}</Typography>
                        {/* Total */}
                        <Typography variant="h5">Total: ${(cart.reduce((a, b) => a + (config?.items.find((i) => i.id === b.id)?.price ?? 0), 0) * (config?.tax_multiplier ?? 1)).toFixed(2)}</Typography>
                        <Typography variant="body1">You will not be charged online. Please bring cash to the next meeting to pay for your items.</Typography>

                        {/* Name */}
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            sx={{ mt: 2 }}
                            onChange={(v) => {
                                setName(v.target.value);
                            }}
                        />

                        {/* Email */}
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            type="email"
                            sx={{ mt: 2 }}
                            onChange={(v) => {
                                setEmail(v.target.value);
                            }}
                        />

                        {/* Unit */}
                        <TextField
                            label="Unit"
                            variant="outlined"
                            fullWidth
                            sx={{ mt: 2 }}
                            onChange={(v) => {
                                setUnit(v.target.value);
                            }}
                        />

                        <Button variant="contained" sx={{ mt: 2 }} fullWidth disabled={!ready} onClick={() => setConfirmDialog(true)}>
                            Checkout
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default ShopCartPage;
