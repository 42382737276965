import { BASE_URL } from "./base";

export function getAllUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/users`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<User[]>;
                    if (!data.success) return reject("Failed to get users");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function updateUser(userID: string, user: Partial<User>): Promise<User> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/user/${userID}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user }),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<User>;
                    if (!data.success) return reject("Failed to update user");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function createUser(user: { name: string; email: string }): Promise<User> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/user`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user }),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<User>;
                    if (!data.success) return reject("Failed to create user");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function deleteUser(userID: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/user/${userID}`, {
            method: "DELETE",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject("Failed to delete user");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function resetUser(userID: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/user/${userID}/reset`, {
            method: "POST",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject("Failed to reset user");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function updateUserPassword(userID: string, old_password: string, new_password: string): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/user/${userID}/password`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ old_password: old_password, new_password: new_password }),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject("Failed to update password");
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
