import { useRef, useState } from "react";
import { getDocumentById } from "../api/docs";

export interface DownloadFileProps {
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
}

export interface DownloadedFileInfo {
    readonly download: (key: string) => Promise<void>;
    // readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
    readonly name: string | undefined;
    readonly url: string | undefined;
}

export const useMultiDownloadDocument = ({ preDownloading, postDownloading, onError }: DownloadFileProps): DownloadedFileInfo => {
    // const ref = useRef<HTMLAnchorElement | null>(null);
    const [url, setFileUrl] = useState<string>();
    const [name, setFileName] = useState<string>();

    const download = async (key: string) => {
        try {
            console.log(key);
            preDownloading();
            const string: Buffer = (await getDocumentById(key)) as unknown as Buffer;
            console.log(string);
            const url = URL.createObjectURL(new Blob([string]));
            setFileUrl(url);
            console.log(url);
            setFileName(key);
            const alink = document.createElement("a");
            alink.href = url;
            alink.download = key;
            alink.click();

            // console.log(ref.current);
            // ref.current?.click();
            postDownloading();
            URL.revokeObjectURL(url);
            console.log("complete");
        } catch (error) {
            console.warn(error);
            onError();
        }
    };

    return { download, url, name };
};
