import { Container, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { fixSiteState } from "../util/site_state";
import { SiteStateContext } from "../contexts/SiteStateContext";
import EditIcon from "@mui/icons-material/Edit";
import MarkdownRender from "../components/MarkdownRender";

interface Props {
    title: string;
    navHighlight: string;
    markdown: string;
}

const MarkdownPage = ({ title, navHighlight, markdown }: Props) => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: title,
                    navHighlight: navHighlight,
                    actions: [
                        {
                            text: "Edit",
                            icon: EditIcon,
                            onClick: () => {
                                console.log("Edit");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState, title, navHighlight]);

    return (
        <Container sx={{ mt: 3 }}>
            <MarkdownRender markdown={markdown} />
        </Container>
    );
};

export default MarkdownPage;
