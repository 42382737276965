import { Button, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { updateUserPassword } from "../api/user";
import { AuthContext } from "../contexts/AuthContext";
import { LoadingButton } from "@mui/lab";
import { fetchAuthUser } from "../api/auth";
import { useNavigate } from "react-router-dom";

interface Props {
    redirect?: string;
}

export function PasswordReset(props: Props) {
    const { authUser, setAuthUser } = useContext(AuthContext);

    const [pwd_text, setPwdText] = useState<string>("");

    const [pwd_cur, setPwdCur] = useState<string>("");
    const [pwd_new, setPwdNew] = useState<string>("");
    const [pwd_con, setPwdCon] = useState<string>("");

    const [saving, setSaving] = useState<boolean>(false);

    const navigate = useNavigate();

    function savePassword() {
        setSaving(true);
        if (pwd_new.length < 7) {
            setSaving(false);
            return setPwdText("Password too short");
        }
        if (pwd_new !== pwd_con) {
            setSaving(false);
            return setPwdText("Passwords do not match");
        }
        if (!authUser) {
            setSaving(false);
            return setPwdText("No auth user");
        }

        updateUserPassword(authUser.id, pwd_cur, pwd_new)
            .then(() => {
                setPwdCur("");
                setPwdNew("");
                setPwdCon("");
                setPwdText("Password updated");
                setSaving(false);
                fetchAuthUser().then((user) => {
                    if (setAuthUser) setAuthUser(user);
                    if (props.redirect) navigate(props.redirect);
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <Typography variant="body2" sx={{ mt: 2, mb: 4 }}>
                {pwd_text && `*${pwd_text}`}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        label="Current Password"
                        onChange={(ev) => {
                            setPwdCur(ev.target.value);
                        }}
                        fullWidth
                        type="password"
                    />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                    <TextField
                        label="New Password"
                        onChange={(ev) => {
                            setPwdNew(ev.target.value);
                        }}
                        fullWidth
                        type="password"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Confirm Password"
                        onChange={(ev) => {
                            setPwdCon(ev.target.value);
                        }}
                        fullWidth
                        type="password"
                    />
                </Grid>
                <Grid item xs={8}>
                    <LoadingButton
                        onClick={() => {
                            savePassword();
                        }}
                        loading={saving}
                    >
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}
