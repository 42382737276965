import { Container, Link, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import CONFIG from "../config";
import { SiteStateContext } from "../contexts/SiteStateContext";
import { fixSiteState } from "../util/site_state";

export function NotFoundPage() {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Page Not Found", navHighlight: "", hideFooter: true }));
        }
    }, [setSiteState]);

    return (
        <Container sx={{ mt: "60px", textAlign: "center" }}>
            <Typography variant="h2" sx={{ m: 4 }}>
                "Let us try to find the arrow"
            </Typography>
            <Typography variant="body1" sx={{ m: 2 }}>
                The page you are looking for can't be found.
            </Typography>
            <Typography variant="body2">
                If you believe this is a mistake, please contact the webmaster (<Link href={`mailto:${CONFIG.webmaster.email}`}>{CONFIG.webmaster.email}</Link>)
            </Typography>
        </Container>
    );
}
