import { BASE_URL } from "./base";

export function getShopConfig(): Promise<Shop_Config> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Shop_Config>;
                    if (!data.success) return reject("Failed to get shop config");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function postShopOrder(name: string, email: string, unit: string, items: Shop_CartItem[]): Promise<Shop_Order> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop/order`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ name, email, unit, items }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Shop_Order>;
                    console.log(data);
                    if (!data.success) return reject("Failed to submit order");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getShopOrders(): Promise<Shop_Order[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop/orders`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Shop_Order[]>;
                    if (!data.success) return reject("Failed to get shop orders");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getPendingOrderCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop/orders/pending/count`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get pending order count");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getOrderById(id: string): Promise<Shop_Order> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop/order/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<Shop_Order>;
                    if (!data.success) return reject("Failed to get shop order");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

// ROOT ONLY METHOD
export function deleteOrderById(id: string) {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/shop/order/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.success) {
                    resolve(true);
                } else {
                    reject(json.message);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}
