import ShortcutIcon from "@mui/icons-material/Shortcut";
import { CircularProgress, Container, List, ListItem, ListItemButton, ListItemText, Pagination, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFeedbackByPage, getFeedbackPageCount } from "../../api/feedback";
import { SiteStateContext } from "../../contexts/SiteStateContext";
import { fixSiteState } from "../../util/site_state";
import AdminLayout from "./AdminLayout";

const AdminMessages = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [feedback, setFeedback] = useState<FeedbackMessage[]>([]);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(
                fixSiteState({
                    title: "Messages - Admin",
                    navHighlight: "/admin/messages",
                    adminPage: true,
                    actions: [
                        {
                            icon: ShortcutIcon,
                            text: "Jump to",
                            onClick: () => {
                                navigate("/feedback");
                            },
                        },
                    ],
                })
            );
        }
    }, [setSiteState]);

    useEffect(() => {
        (async () => {
            setPageCount(await getFeedbackPageCount());
            setFeedback(await getFeedbackByPage(page - 1));
            setLoading(false);
        })();
    }, [page, setPageCount, setFeedback]);

    function MessageList() {
        return (
            <>
                <List sx={{ bgcolor: "background.paper" }}>
                    {feedback.map((message) => (
                        <ListItem key={message.id} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    navigate(`/admin/messages/${message.id}`);
                                }}
                            >
                                <ListItemText primary={`${!message.read ? "UNREAD: " : ""}${message.subject}`} secondary={dayjs(message.timestamp).toString()} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Pagination count={pageCount} page={page} onChange={(e: any, page: number) => setPage(page)} sx={{ mt: 3 }} />
            </>
        );
    }

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Messages
                </Typography>
                {loading ? <CircularProgress /> : <>{feedback.length === 0 ? <Typography variant="h5">No messages to display.</Typography> : <MessageList />}</>}
            </Container>
        </AdminLayout>
    );
};

export default AdminMessages;
