import { SiteState, SiteStateInput } from "../contexts/SiteStateContext";

export function fixSiteState(siteState: SiteStateInput): SiteState {
    return {
        title: siteState.title,
        navHighlight: siteState.navHighlight,
        actions: siteState.actions ?? [],
        adminPage: siteState.adminPage ?? false,
        hideFooter: siteState.hideFooter ?? siteState.adminPage ?? false,
    };
}
