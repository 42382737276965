import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Container, Grid, Pagination, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAuditLogPageCount, getAuditLogs } from "../../../api/log";
import { AuthContext } from "../../../contexts/AuthContext";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";
import ReactDiffViewer from "react-diff-viewer";

const AdminAuditLog = () => {
    const { siteState, setSiteState } = useContext(SiteStateContext);

    const [logEntries, setLogEntries] = useState<AuditLogEntry[]>([]);
    const [page, setPage] = useState<number>(1);
    const [numPages, setNumPages] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const { authUser, setAuthUser } = useContext(AuthContext);

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Audit Log - Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    useEffect(() => {
        (async () => {
            setNumPages(await getAuditLogPageCount());
            setLogEntries(await getAuditLogs(page - 1));
            setLoading(false);
        })();
    }, [page, setNumPages, setLogEntries]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Audit Log
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        {logEntries.map((entry) => (
                            <Accordion key={entry.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography sx={{ width: "33%", flexShrink: 0 }}>{entry.title}</Typography>
                                    <Typography sx={{ color: "text.secondary" }}>{entry.byUser ? entry.actor.name : "by system"}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{entry.description}</Typography>
                                    <Typography sx={{ color: "text.secondary" }}>{new Date(entry.timestamp).toISOString()}</Typography>
                                    {entry.isDiff && (
                                        <ReactDiffViewer oldValue={entry.diff_a} newValue={entry.diff_b} splitView={true} />

                                        // <Grid container spacing={1}>
                                        //     <Grid item xs={12} md={6}>
                                        //         <Typography>Old:</Typography>
                                        //         <pre style={{ overflowX: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{entry.diff_a}</pre>
                                        //     </Grid>
                                        //     <Grid item xs={12} md={6}>
                                        //         <Typography>New:</Typography>
                                        //         <pre style={{ overflowX: "auto", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{entry.diff_b}</pre>
                                        //     </Grid>
                                        // </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <Pagination count={numPages} page={page} onChange={(e: any, page: number) => setPage(page)} sx={{ mt: 3 }} />
                    </>
                )}
            </Container>
        </AdminLayout>
    );
};

export default AdminAuditLog;
