import { BASE_URL } from "./base";

export function getAuditLogPageCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auditlog/pagecount`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get audit log page count");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getAuditLogCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auditlog/count`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get audit log count");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getAuditLogs(page: number): Promise<AuditLogEntry[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/auditlog/${page}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<AuditLogEntry[]>;
                    if (!data.success) return reject("Failed to get audit logs");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
