import { BASE_URL } from "./base";

export function getUnreadFeedbackCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/feedback/count/unread`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    if (!data.success) return reject("Failed to get feedback count");
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function sendFeedback(feedback: FeedbackMessagePost): Promise<void> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/feedback`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(feedback),
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<void>;
                    if (!data.success) return reject(data.message);
                    resolve();
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getFeedbackById(id: string): Promise<FeedbackMessage> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/feedback/${id}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<FeedbackMessage>;
                    if (!data.success) return reject(data.message);
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getFeedbackByPage(page: number): Promise<FeedbackMessage[]> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/feedback/page/${page}`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<FeedbackMessage[]>;
                    if (!data.success) return reject(data.message);
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}

export function getFeedbackPageCount(): Promise<number> {
    return new Promise((resolve, reject) => {
        fetch(`${BASE_URL}/feedback/pagecount`, {
            method: "GET",
            credentials: "include",
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = (await res.json()) as BaseApiResponse<number>;
                    console.log(data);
                    if (!data.success) return reject(data.message);
                    resolve(data.data);
                } else {
                    reject("Web<>API Error");
                }
            })
            .catch((err) => {
                console.error(err);
                reject("No connection to API");
            });
    });
}
