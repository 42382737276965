import { Container, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { SiteStateContext } from "../../../contexts/SiteStateContext";
import { fixSiteState } from "../../../util/site_state";
import AdminLayout from "../AdminLayout";

interface MenuItem {
    title: string;
    link: string;
    minRole?: "root" | "admin" | "user";
}

const items: MenuItem[] = [
    {
        title: "Audit Log",
        link: "/admin/advanced/audit-log",
    },
    {
        title: "Users",
        link: "/admin/advanced/users",
    },
    // {
    //     title: "Site Announcements",
    //     link: "/admin/advanced/site-announcements",
    // },
    {
        title: "Site Manager",
        link: "/admin/advanced/site-manager",
    },
    {
        title: "Dev",
        link: "/admin/advanced/dev",
        minRole: "root",
    },
];

const AdminAdvanced = () => {
    const { setSiteState } = useContext(SiteStateContext);

    const { authUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (authUser?.role !== "admin" && authUser?.role !== "root") {
            navigate("/admin");
        }
    });

    useEffect(() => {
        if (setSiteState) {
            setSiteState(fixSiteState({ title: "Advanced - Admin", navHighlight: "/admin/advanced", adminPage: true }));
        }
    }, [setSiteState]);

    return (
        <AdminLayout>
            <Container sx={{ m: 0 }} maxWidth={false}>
                <Typography variant="h2" sx={{ mt: 2, mb: 4 }}>
                    Advanced
                </Typography>
                <List sx={{ bgcolor: "background.paper" }}>
                    {items.map((item) => {
                        if (item.minRole && authUser?.role !== item.minRole) {
                            return null;
                        }
                        return (
                            <ListItem disablePadding key={item.link}>
                                <ListItemButton href={item.link}>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Container>
        </AdminLayout>
    );
};

export default AdminAdvanced;
