import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, TextField } from "@mui/material";
import type { Identifier, XYCoord } from "dnd-core";
import { FC, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

const style = {
    // border: "1px dashed gray",
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    cursor: "move",
};

export interface CardProps {
    id: any;
    officer: OfficerPageComponent;
    index: number;
    moveOfficer: (dragIndex: number, hoverIndex: number) => void;
    updateOfficer: (officer: OfficerPageComponent) => void;
    deleteOfficer: () => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const Card: FC<CardProps> = ({ id, officer, index, moveOfficer, updateOfficer, deleteOfficer }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: "card",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveOfficer(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <Paper
            ref={ref}
            style={{ ...style, opacity }}
            data-handler-id={handlerId}
            onContextMenu={(ev) => {
                ev.preventDefault();
                setAnchorEl(ev.currentTarget);
            }}
        >
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        deleteOfficer();
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
            {officer.isLine ? (
                <Divider>Divider</Divider>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            value={officer.title}
                            label="Title"
                            variant="standard"
                            fullWidth
                            onChange={(ev) => {
                                officer.title = ev.target.value;
                                updateOfficer(officer);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={1}>
                        <Select
                            value={officer.unit.type}
                            label="Unit Type"
                            fullWidth
                            onChange={(ev) => {
                                officer.unit.type = ev.target.value as UnitTypeEnum;
                                updateOfficer(officer);
                            }}
                        >
                            <MenuItem value={"none"}>none</MenuItem>
                            <MenuItem value={"pack"}>pack</MenuItem>
                            <MenuItem value={"troop"}>troop</MenuItem>
                            <MenuItem value={"crew"}>crew</MenuItem>
                            <MenuItem value={"ship"}>ship</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} lg={1}>
                        <TextField
                            value={officer.unit.num}
                            label="Unit #"
                            variant="standard"
                            fullWidth
                            onChange={(ev) => {
                                officer.unit.num = parseInt(ev.target.value);
                                updateOfficer(officer);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={officer.name}
                            label="Name"
                            variant="standard"
                            fullWidth
                            onChange={(ev) => {
                                officer.name = ev.target.value;
                                updateOfficer(officer);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={officer.email}
                            label="E-Mail"
                            variant="standard"
                            fullWidth
                            onChange={(ev) => {
                                officer.email = ev.target.value;
                                updateOfficer(officer);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Select
                            value={officer.honor}
                            label="Honor"
                            fullWidth
                            onChange={(ev) => {
                                officer.honor = ev.target.value as HonorEnum;
                                updateOfficer(officer);
                            }}
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                            <MenuItem value={"ordeal"}>Ordeal</MenuItem>
                            <MenuItem value={"brotherhood"}>Brotherhood</MenuItem>
                            <MenuItem value={"vigil"}>Vigil</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};
